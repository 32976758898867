main {
    height: 100%;
    padding: 10.25em var(--spacing-3) var(--spacing-4)  var(--spacing-3);
    
    .intro {
        display: flex;
        gap: var(--spacing-4);
        
    }

    .content-left {
        width: 50%;
    }

    h1 {
        line-height: 1em;
    }

    .content-left h3 {
        padding-top: var(--spacing-2);
    }

    .coding-lang {
        display: flex;
        gap: var(--spacing-2);
        padding-top: var(--spacing-4);

    }

    .coding-lang svg {
        width: 2.5em;
    }

    .content-right {
        width: 50%;
    }

    .outstanding {
        background-image: url('../imgs/vslr_00.webp');
        justify-content: center;
        background-size: cover; /* Mantener cover para el tamaño inicial */
        background-position: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1em;
        border-radius: 0.2em;
        transition: background-size 0.6s ease-in-out;
        border-image: fill 0 linear-gradient(180deg, rgba(0,0,0,0) 64%, rgba(0,0,0,0.6643032212885154) 87%, rgba(0,0,0,0.8435749299719888) 100%);
    }
    
    
    

    .out-info {
        margin-top: auto;
    }

    .out-info p {
        padding-top: 1em;
    }

    .project-summary {
        padding: var(--spacing-4) 0 var(--spacing-4) 0;
    }

    .project-summary p {
        padding-top: 0.6em;
    }

    .article-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--spacing-2);
        padding-top: var(--spacing-2);
    }

    .article-list h3 {
        padding-top: 0.6em;
        padding-bottom: 0.4em;
    }

    .article-list p {
        padding-bottom: 1em;
    }

    .image-preview {
        width: 100%;
        height: auto;
        border-radius: 0.2em;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        object-fit: cover;
    }
    
    .image-preview img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: transform 0.6s ease;
    }
    
    .image-preview img:hover  {
        transform: scale(1.1);
    }
    
    
    
    article a {
        color: var(--content-secondary-dark);
    }

    article svg {
        stroke: var(--content-secondary-dark);
    }

    @media (max-width: 690px) {
        .image-preview {
            height: fit-content;
        
        }
        .intro {
            flex-direction: column;
        }

        .content-left, .content-right {
            width: 100%;
        }

        .outstanding {
            height: 18em;
        }

        .article-list {
            grid-template-columns: repeat(1, 1fr);
        }

        article {
            padding-top: 2em;
        }

        .coding-lang {
            justify-content: space-between;
            width: 100%;
        }
    }

    @media (min-width: 1352px) {
        padding: 10.25em 14em 6.25em 14em;
    }

    @media screen and (max-width: 690px) {
        padding: 6em 1em 1em  1em;
    }
    
}