footer {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2em;
    padding: var(--spacing-2) var(--spacing-3);
    background-color: var(--background-primary-dark);
    z-index: 99;

    li {
        list-style: none;
    }

    a {
        font-size: 0.87em;
        display: flex;
        align-items: center;
    }

    svg {
        width: 1.2em;
        fill: white;
        margin-right: 0.7em;
    }

    .footer-desc {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 0.2em 0;

        p {
            margin-top: auto;
            color: var(--content-secondary-dark);
        }
    }

    .media {
        display: flex;
        gap: 1.4em;
    }

    span {
        font-size: 0.7em;
        color: var(--content-secondary-dark);
        line-height: 2em;
    }

    .mail, .footer-contact, .link-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 4em;
    }

    @media (min-width: 1352px) {
        padding: var(--spacing-2) 14em var(--spacing-2) 14em;
    }

    @media screen and (max-width: 690px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        nav, .mail {
            display: none;
        }
        
    }
    

}