main {
    .articles {

        h3 {
            display: flex;
            align-items: center;
            padding-bottom: 0.7em;
        }

        svg {
            margin-right: 0.4em;
            size: fixed;
        }
    }

    .intro-section {
        color: var(--content-secondary-dark);
        padding: 0.7em 0em 2em 0em;
    }

    .article-box {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        padding: 1em;
        border-radius: 0.2em;
        border: 0.2em solid #252525;
        height: fit-content;
    }

    .article-img {
        width: 12em;
        margin-right: 2em;
        
    }

    .article-img img {
        width: 100%;
        height: auto;
        
    }

    .article-text {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        h3 {
            display: block;
            line-height: 1.4em;
        }

        p {
            padding-bottom: 1em;
        }
    }

    .info-date {
        display: flex;
        justify-content: space-between;
        color: var(--content-secondary-dark);
    }

    a svg {
        margin-left: 0.4em;
    }

    @media screen and (max-width: 690px) {
        .article-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .article-img {
            width: 100%;
            height: auto;
            margin-bottom: 2em;
            margin-right: 0em;
        }
    }

    /* Books */

    .books {
        padding-top: 4em;
    }

    .book-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 4em;
    }

    .book-image {
        width: auto;
        height: fit-content;
        overflow: hidden;
        margin-bottom: 1em;
        border-radius: 0.2em;
    }

    .book-image img {
        size: 100%;
        object-fit: cover;
    }

    .book-text h3, p {
        margin-bottom: 0.4em;
    }

    @media screen and (max-width: 750px) {
        .book-box {
            grid-template-columns: repeat(2, 1fr);
            gap: 2em;
        }
    }

    @media screen and (max-width: 345px) {
        .book-box {
            grid-template-columns: repeat(1, 1fr);
        }
    }

}
