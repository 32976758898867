.about-page {
    display: flex;
    justify-content: center;
    background-image: url('../imgs/about-bg.png');
    box-shadow: -1px 22px 66px 0px rgba(0,0,0,0.5);
    background-size: cover;
    width: 100%;
    padding-bottom: 8em;


    .about-me {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 2em 4em;
        background-color: rgba(0, 0, 0, 0.123); /* Ajusta la opacidad aquí */
        border-radius: 0.2em;
        backdrop-filter: blur(0.7em);
        height: fit-content;
    }
    
    
    
    .about-left {
        width: 12em;
        height: 12em;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        margin-right: 4em;
    }
    
    .about-left img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    
    .about-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        width: 25%;
        height: 100%;
    }
    
    .about-right h3 {
        margin-bottom: 10px;
    }
    
    .self-description {
        margin-top: 2em;
        padding-bottom: 2em;
    }
    
    .icon-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .icon-desc-list {
        display: flex;
        justify-content: space-between;
        padding-top: 2em;
    }

      /* 2.2 Buttons */
  #pdf-button {
    border: none;
    border-radius: 0.2em;
    padding: 0.5em 1em 0.5em 1em;
    margin-bottom: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    color: var(--content-inverse-dark);
    background-color: var(--background-primary-light);

    svg {
      width: 1.25em; 
      height: 1.25em;
      stroke: var(--content-inverse-dark);
    }
  }


  #pdf-button:hover {
    background-color: var(--content-hover-dark);

    svg {
      transition: transform 0.6s ease;
      transform: translate(0.1em, -0.1em);
    }
  }

  #pdf-button:active {
    background-color: var(--content-pressed-dark);
  }

    @media (max-width: 1750px) {

        .about-me {
            display: flex;
            flex-direction: column;
        }

        .about-right {
            width: 100%;
            align-items: center;
        }
        .about-left {
            margin-right: 0;
            margin-bottom: 2em;
        }
        .icon-desc-list {
            gap: 1.2em;
        }
        .self-description, .intro-description {
            text-align: center;
        }

    }
    
    @media screen and (max-width: 690px) {
        padding-bottom: 4em;
        
        .about-me {
            width: 100%;
            padding: 1em;
        }
        .self-description {

            span {
                display: none;
            }
        }
    }
}



