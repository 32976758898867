header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-2) var(--spacing-3);
    position: fixed;
    backdrop-filter: blur(1.25em);
    width: 100%;
    z-index: 99;
}

header li {
    list-style: none;
}

header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

nav.cabecera-nav {
    width: 60%;
}

button.nav-btn {
    border: none;
    background: none;
    display: none;
}

button.nav-btn svg {
    stroke: var(--content-primary-dark);
}

a.contact {
    border: none;
    border-radius: 0.2em;
    padding: 0.5em 1em 0.5em 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--content-inverse-dark);
    background-color: var(--background-primary-light);

    svg {
      width: 1.25em; 
      height: 1.25em;
      stroke: var(--content-inverse-dark);
    }
}

  /* 2.2 Buttons */
  #link-button {
    border: none;
    border-radius: 0.2em;
    padding: 0.5em 1em 0.5em 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--content-inverse-dark);
    background-color: var(--background-primary-light);

    svg {
      width: 1.25em; 
      height: 1.25em;
      stroke: var(--content-inverse-dark);
    }
  }


  #link-button:hover {
    background-color: var(--content-hover-dark);

    svg {
      transition: transform 0.6s ease;
      transform: translate(0.1em, -0.1em);
    }
  }

  #link-button:active {
    background-color: var(--content-pressed-dark);
  }




@media (min-width: 1352px) {
    header {
        padding: var(--spacing-2) 14em var(--spacing-2) 14em;
    }

    header li {
        list-style: none;
    }
    

}

@media screen and (max-width: 690px) {
    header {
        justify-content: center;
        align-items: center;
        padding: 1em;
    }

    button.nav-btn {
        display: flex;
        padding: 0;
    }

    ul {
        flex-direction: column;
        gap: 2em;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    nav.cabecera-nav {
        justify-content: center;
        width: 100%;
        display: flex;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: height 0.6s ease, opacity 0.5s ease;
    }

    nav.isActive {
        height: 100vh;
        align-items: baseline;
        padding-top: 6em;
        opacity: 1;
    }

    .top-hdr {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
}
