main {

    .project-title h3 {
        line-height: 1.6em;
        padding-top: 1em;
        padding-bottom: 1em
    }

    .project-title p {
        color: var(--content-secondary-dark);
    }

    .cover-project {
        width: 100%;
        height: 24em;
        display: flex;
        justify-content: center;
        padding-bottom: 4em;
    }

    .cover-project img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.2em;
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        column-gap: 2em;
        row-gap: 2.6em;
        padding: 4em 0;
    }

    .gallery svg {
        stroke: var(--content-secondary-dark);
        width: 1.2em;
        margin-right: 0.4em;
    }

    .gallery-info {
        display: flex;
        flex-direction: column;
    }

    .gallery-text {
        padding-top: 0.75em;
        color: var(--content-secondary-dark);
        display: flex;
        align-items: center;
    }

    .gallery-photo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .gallery-photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.2em;
    }

    .project-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 4em;
        padding-bottom: 2em;
    }

    .project-nav a {
        width: 100%;
        display: flex;
        justify-content: center;
        svg {
            transition: transform 0.6s ease;
            margin-left: 0.2em;
            margin-right: 0.2em;
        }
    }
    
    .project-nav a:hover {
        svg {
            transform: translateY(-0.2em);
        }
    }

    .project-nav #left:hover {
        svg {
            transform: translateX(-0.2em);
        }
    }

    .project-nav #right:hover {
        svg {
            transform: translateX(0.2em);
        }
    }

    .project-paragraf {
        padding-bottom: 4em;
    }
    .project-paragraf h3{
        padding-bottom: 1em;
    }

    .extra-paragraf {
        padding-top: 1em;
    }

    .icon {
        display: flex;
        align-items: center;
        
        svg {
            width: 4em;
            height: 4em;
            padding-right: 1em;
            stroke: 0.6em solid white;
        }
    }

    .box-icons {
        padding: 1em 2em;
        background-color: var(--semantic-blue);
        border-radius: 0.2em;
        margin-bottom: 4em;
    }

    .box-icons h3 {
        padding-bottom: 0.8em;

    }

    .my-icons{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2em;
    }

    .project-exp {
        padding: 1em 0;
    }

    .project-exp h3{
        padding: 0.4em 0;
    }

    @media screen and (max-width: 690px) {
        .gallery {
            grid-template-columns: repeat(1, 4fr);
            row-gap: 1.6em;
        }

        .my-icons{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1em;
        }
    }
    
    
}