@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ---------------------------------------------------------------
   Styles/Variables CSS for PERSONAL PORTFOLIO
   AutHor: Carles Busquets
   Version: 1.0.0
   Fecha: 25/06/2024 Last modificacion: 27/06/2024
------------------------------------------------------------------ */

/* 
  1. Variables

    1.  Fonts
        1.1 Main font

        1.2 Font sizes
            1.2.1 Body
            1.2.2 Headers

    2.  Color Styles
        2.1 Light Mode
            2.1.1 Content Light
            2.1.2 States Light
            2.1.3 Background Light
        2.1 Dark Mode
            2.1.1 Content Dark
            2.1.2 States Dark
            2.1.3 Background Dark

    3.  Gloval Styles
        3.1 Shadows
        3.2 Spacing


  2. Styles

    1.  Fonts
          1.1 Font Styles
              1.1.1 Font configuration
              1.1.2 Font Sizes

    2.  Clickers
          2.1 Links
          2.2 Buttons

    2. Styles

*/

:root {

  /* VARIABLES */

  /* ---------------------------------------------------------------
  1. Fonts
  --------------------------------------------------------------- */

  /* 1.1 Main font */

  /* 1.2 Font Sizes */

      /* 1.2.1 Body */
      --body: 16px;

      /* 1.2.2 Headers */
      --h1: 2em;
      --h2: 1.5em;
      --h3: 1.31em;

  /* ---------------------------------------------------------------
  2. Color Styles
  --------------------------------------------------------------- */

  /* 2.1  Light Mode */

      /* 2.1.1 Content */
      --content-primary-light: #171717;
      --content-secondary-light: #74777C;
      --content-inverse-light: #FFFFFF;

      /* 2.1.2 States */
      --content-disabled-light: #8E9196;
      --content-pressed-light: #5E6063;
      

      /* 2.1.3 Background */
      --background-primary-light: #FFFFFF;
      --background-secondary-light: #F6F7F8;
      --background-tertiary-light: #E5E5E5;

  /* 2.2  Dark Mode */

      /* 2.2.1 Content */
      --content-primary-dark: #FFFFFF;
      --content-secondary-dark: #989898;
      --content-inverse-dark: #171717;

      /* 2.2.2 States */
      --content-hover-dark: #c4c4c4;
      --content-pressed-dark: #9a9da1;
      --content-dissabled-dark: #E8E8ED;

      /* 2.2.3 Background */
      --background-primary-dark: #000000;
      --background-secondary-dark: #212121;
      --background-tertiary-dark: #2F3030;

      --semantic-blue: #0091EA;



    /* 2. Gradients */

    /* 2.1 Gradient Background */
    --color-bg-gradient-1: #000000;
    --color-bg-gradient-2: #00082c;

    /* 2.2 Gradient Colors*/
    --color-gd-1: 18, 113, 255;
    --color-gd-2: 221, 74, 255;
    --color-gd-3: 100, 220, 255;
    --color-gd-4: 200, 50, 50;
    --color-gd-5: 180, 180, 50;
    --color-gd-inactive: 148, 188, 255;
    --circle-size: 80%;
    --blending: hard-light;
      
      

  /* ---------------------------------------------------------------
  3. Gloval Styles
  --------------------------------------------------------------- */

  /* 3.1 Shadows */
  --box-shadow: 0px 10px 30px -1px rgba(0,0,0,0.32);

  /* 3.2 Spacing */
  --spacing-1: 0.75em;        /* 12px */
  --spacing-2: 1.5em;         /* 24px */
  --spacing-3: 3em;           /* 48px */
  --spacing-4: 4.25em;        /* 68px */

}


body {

  /* STYLES */

  background-color: var(--background-primary-dark);
  color: var(--content-primary-dark);

  /* ---------------------------------------------------------------
  1. Fonts
  --------------------------------------------------------------- */

  /* 1.1 Font Styles */
    
    /* 1.1.1 Font configuration */
    margin: 0;
    font-family: 'Inter';

    /* 1.2 Font Sizes */
    h1 {
      font-size: 3em;         /* 48px */
      font-weight: 600;
    }

    h2 {
      font-size: 2em;         /* 32px */
      font-weight: 400;
    }

    h3 {
      font-size: 1.31em;      /* 21px */
      font-weight: 600;
    }

    p {
      font-size: 0.87em;      /* 16px */
      font-weight: 400;
      line-height: 1.6em;
    }

  /* ---------------------------------------------------------------
  2. Clickers
  --------------------------------------------------------------- */

  svg {
    width: 1.25em; 
    height: 1.25em;
  }
  
  /* 2.1 Links */
  a {
    font-size: 0.87em;
    text-decoration: none;
    color: var(--content-primary-dark);
    svg {
      fill: var(--content-primary-dark);
      stroke: var(--content-primary-dark);
    }
  }
  a:hover {
    color: var(--content-hover-dark);

    svg {
      fill: var(--content-hover-dark);
      stroke: var(--content-hover-dark);

      transition: transform 0.6s ease;
      transform: translate(0.1em, -0.1em);
    }
  }
  a:active {
    color: var(--content-pressed-dark);

    svg {
      fill: var(--content-pressed-dark);
      stroke: var(--content-pressed-dark);
    }
  }

  /* 2.2 Buttons */
  button {
    border: none;
    border-radius: 0.2em;
    padding: 0.5em 1em 0.5em 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    svg {
      width: 1.25em; 
      height: 1.25em;
    }
  }

  /* Button PRIMARY */
  button.primary {
    color: var(--content-inverse-dark);
    background-color: var(--background-primary-light);

    svg {
      stroke: var(--content-inverse-dark);
    }
  }

  button.primary:hover {
    background-color: var(--content-hover-dark);

    svg {
      transition: transform 0.6s ease;
      transform: translate(0.1em, -0.1em);
    }
  }

  button.primary:active {
    background-color: var(--content-pressed-dark);
  }

  /* Button SECONDARY */
  button.secondary {
    color: var(--content-primary-dark);
    border: 0.09em white solid;
    background: none;

    svg {
      stroke: var(--content-primary-dark);
    }
  }

  button.secondary:hover {
    color: var(--content-hover-dark);
    border: 0.09em var(--content-hover-dark) solid;

    svg {
      stroke: var(--content-hover-dark);
      transition: transform 0.3s ease;
      transform: translate(0.1em, -0.1em);
    }
  }

  button.secondary:active {
    color: var(--content-pressed-dark);
    border: 0.09em var(--content-pressed-dark) solid;

    svg {
      stroke: var(--content-pressed-dark);
    }
  }

}
